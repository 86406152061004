import { all, call, fork, put, takeEvery } from 'redux-saga/effects'
import { CODE_POST_MONDAY, CODE_GET_VALID, CODE_POST_REDEEM_CORE } from '../actions/types'
import { setTicketData, SetCodeValid, popupError, popupSuccess } from '../actions'
import axios from 'axios'
import moment from 'moment'
var mondaySchema = {
  date4: {
    date: '',
  },
  phone4: '',
  __________1: '',
  corporate8: {
    labels: ["SILVER VOYAGE"],
  },
  corporate: {
    label: "งานใหม่",
  },
  email4: '',
  dropdown0: {
    labels: ["THAILAND"],
  },
  status92: {},
  ______________: '',
  text27: '',
  status29: {
    label: "งานส่งออกไปสนามบิน",
  },
  status4: {
    label: "ROBOT",
  },
  date5: {
    date: '',
  },
  status6: {
    label: "System",
  },
  long_text5: { text: '' },
  long_text0: { text: '' },
  long_text54: { text: '' },
}

function typeCoupon(mondayValue) {
  let data = JSON.parse(mondayValue)

  try {
    if (data.index === 0) {
      return 'DISCOUNT'
    } else if (data.index === 2) {
      return 'COUPON'
    } else {
      return false
    }
  } catch (error) {
    return false
  }
}
function discountType(mondayValue) {
  let data = JSON.parse(mondayValue)

  try {
    if (data.index === 0) {
      return 'VALUE'
    } else if (data.index === 2) {
      return 'PERCENT'
    } else {
      return false
    }
  } catch (error) {
    return false
  }
}

function discountValue(mondayValue) {
  try {
    let data = parseInt(mondayValue.replace(/"/g, ''))
    return data
  } catch (error) {
    return false
  }
}

var booking_form = [
  'fullname',
  'firstname',
  'lastname',
  'mobile',
  'email',
  'booking_date',
  'booking_time',
  'person',
  'luggage',
  'pick_up_type',
  'pick_up_address',
  'pick_up_landmark',
  'pick_up_airport',
  'pick_up_flight_number',
  'drop_off_type',
  'drop_off_address',
  'drop_off_landmark',
  'drop_off_airport',
  'drop_off_flight_number',
  'note',
]

const addField = (data) => {
  let tempData = []
  booking_form.map((field, index) => {
    let object = {}
    object['field_name'] = field
    object['field_value'] = data[index]

    tempData.push(object)
  })

  return tempData
  //{"field_name":"address","field_value":"Central World2"}
}
const postToMondayRequest = async (payload) => {
  console.log('payload', payload)
  const statusJob = () => {
    if (payload.data.pickupType === '1' && payload.data.dropoffType === '1') return "รับจากสถานที่ไปส่งสถานที่"
    else if (payload.data.dropoffType === '2' && payload.data.drop_off_airport === '2') return "ดอนเมืองในประเทศ"
    else if (payload.data.dropoffType === '2' && payload.data.drop_off_airport === '1') return "งานส่งออกไปสนามบิน"
    else if (payload.data.pickupType === '2' && payload.data.pick_up_airport === '1') return "รับจากสุวรรณภูมิ"
    else if (payload.data.pickupType === '2' && payload.data.pick_up_airport === '2') return "ดอนเมืองในประเทศ"
    return ""
  }

  const cartype = () => {
    
    if (payload.data.car_type === 'BMW Series 5') return "BMW Series 5"
    else if (payload.data.car_type === '14') return "Majesty"
    else if (payload.data.car_type === '4') return "ALPHARD"
    else if (payload.data.car_type === '3') return "H1"
    else if (payload.data.car_type === '1') return "CAMRY"
    else if (payload.data.car_type === '6') return "BENZ E CLASS"
    else if (payload.data.car_type === '15') return "EV ECO SEDAN"
    return ""
  }

  mondaySchema.date4.date = moment(payload.data.Datetime).format('YYYY-MM-DD')
  mondaySchema.phone4 = payload.data.phone
  mondaySchema.__________1 = payload.data.bookingTime
  mondaySchema.email4 = payload.data.email
  mondaySchema.status92.label = cartype()
  mondaySchema.______________ = payload.data.person + '/' + payload.data.luggage
  mondaySchema.text27 = payload.data.pick_upFlight || payload.data.drop_offFlight
  mondaySchema.status29.label = statusJob()

  mondaySchema.long_text54.text = payload.data.note
  mondaySchema.text0 = ''
  mondaySchema.long_text54.text += ' // รหัสอ้างอิงที่ : ' + payload.itemMondayId + '  //  '

  // if (payload.data.coupon.validationCodeFromServer.coupon_status === "active"){
  //   mondaySchema.long_text54.text += "รหัสนี้ได้ใช้งานไปแล้วในรายการการจองที่ " + payload.data.coupon.validationCodeFromServer.booking_id
  //   mondaySchema.text0 +=  "(รหัสนี้ได้ใช้งานไปแล้ว ตรวจสอบและติดต่อกลับหาลูกค้า) "
  // }

  mondaySchema.text0 += payload.code

  mondaySchema.date5.date = moment(payload.data.startDate).format('YYYY-MM-DD')
  mondaySchema.long_text0.text =
    payload.data.dropoffType === '1'
      ? payload.data.drop_offAddress + payload.data.drop_off_interest
      : payload.data.drop_off_airport === '1'
      ? 'SUVANABHUMI Airport'
      : 'DON MUANG Airport'

  mondaySchema.long_text5.text =
    payload.data.pickupType === '1'
      ? payload.data.pick_upAddress + payload.data.pick_up_interest
      : payload.data.pick_up_airport === '1'
      ? 'SUVANABHUMI Airport'
      : 'DON MUANG Airport'
  if (payload.data.netPrice !== '') {
    mondaySchema.numbers = payload.data.netPrice
  }
  ///
  var ColumnValue = JSON.stringify(mondaySchema)

  const body = {
    query: `mutation ($board: Int!, $name: String!,  $group: String!, $column: JSON!) { 
		create_item( board_id: $board, item_name: $name,group_id :$group , column_values:$column , create_labels_if_missing: true) { 
		  id 
		  column_values { 
			id 
			value 
		  } 
		} 
	  } `,
    variables: {
      board: parseInt(process.env.REACT_APP_MONDAY_Reservation_Limousine),
      name: payload.data.firstname + ' ' + payload.data.lastname,
      group: 'new_group82700',
      column: ColumnValue,
    },
  }
  debugger
  return await axios
    .post(process.env.REACT_APP_MONDAY_URL_QRAPH, body)
    .catch((err) => {
      console.error(err.data)
    })
    .then((res) => {
      console.log(res.data)
      return res.data
    })
}

const getCodeValidRequest = async (payload) => {
  //change to monday
  console.log('payload', payload)
  const body = {
    query: `query($board: Int!, $column_id: String!,  $column_value: String!) {
      items_by_column_values (board_id:  $board, column_id:  $column_id, column_value:  $column_value) {
      id
      column_values { 
        id 
        value 
        } 
      }
      } `,
    variables: {
      board: parseInt(process.env.REACT_APP_MONDAY_SILVER_VOYAGE_Code),
      column_id: 'text8',
      column_value: payload.Code,
    },
  }

  return await axios
    .post(process.env.REACT_APP_MONDAY_URL_QRAPH, body)
    .catch((err) => {
      console.error(err.data)
    })
    .then((res) => {
      return res.data
    })
}

const postUpdatevalue = async (payload) => {
  //change to monday
  console.log('payload', payload)
  const body = {
    query: `mutation($board: Int!, $column_id: String!, $item_id: Int!,  $value: JSON!) {
      change_column_value (board_id:  $board, column_id:  $column_id,item_id:  $item_id, value:  $value) {
      id
      }
      } `,
    variables: {
      board: parseInt(process.env.REACT_APP_MONDAY_SILVER_VOYAGE_Code),
      item_id: Number(payload.itemMondayId),
      column_id: 'status',
      value: '{"index": 2}',
    },
  }

  return await axios
    .post(process.env.REACT_APP_MONDAY_URL_QRAPH, body)
    .catch((err) => {
      console.error(err.data)
    })
    .then((res) => {
      return res.data
    })
}

const postToCoreRequest = async (payload) => {
  console.log('payload', payload)
  let url = process.env.REACT_APP_API_URL + '/v1/redemptions'

  return await axios
    .post(
      url,
      {
        privilege_id: payload.privilege_id,
        ref_1: payload.data.firstname,
        ref_2: payload.ticketID,
        coupons: [
          {
            coupon_id: payload.coupon_id,
            coupon_code: payload.code,
          },
        ],
      },
      {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('accesstokens'),
        },
      }
    )
    .catch((err) => {
      console.error(err.error)
    })
    .then((res) => {
      console.log(res)
      return res
    })
}

const putBookingToCoreRequest = async (payload) => {
  console.log('payload_Booking', payload)
  //TODO : add field
  let url = process.env.REACT_APP_API_URL + '/v1/bookings/' + payload.booking_id

  return await axios
    .put(
      url,
      {
        booking_status: 'book',
        input_data: payload.field,
      },
      {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('accesstokens'),
        },
      }
    )
    .catch((err) => {
      console.error(err.error)
    })
    .then((res) => {
      console.log(res)
      return res
    })
}

function* postToMonday({ payload }) {
  try {
    const valid = yield call(getCodeValidRequest, payload)
    if (valid) {
      const data = yield call(postToMondayRequest, payload)
      if (data) {
        let updateData = false
        if (payload.itemMondayId === '-') {
          updateData = true
        } else {
          updateData = yield call(postUpdatevalue, payload)
        }

        if (updateData) {
          yield put(popupSuccess())
          yield put(setTicketData(data.data.create_item.id))
        } else {
          throw new Error(data.err_code)
        }
      } else {
        throw new Error(data.err_code)
      }
    } else {
      throw new Error(valid.err_code)
    }
  } catch (error) {
    yield put(popupError('การส่งข้อมูลไม่สำเร็จ'))
  }
}

function* codeValidation({ payload }) {
  try {
    const data = yield call(getCodeValidRequest, payload)

    // parse json from monday
    if (data) {
      var flagAllbeUsed = false
      var refcodeItem = []
      data.data.mapping.map((codeItem) => {
        let status = JSON.parse(codeItem.status)

        codeItem.couponType = typeCoupon(codeItem.status2)
        codeItem.discountType = discountType(codeItem.type)
        codeItem.discountValue = discountValue(codeItem.discount_value3)

        if (status.index === 1) {
          flagAllbeUsed = true
          if (codeItem.couponType && codeItem.discountType && codeItem.discountValue) {
            refcodeItem.push(codeItem)
          }
        }
      })
      if (!flagAllbeUsed) {
        yield put(popupError('รหัสนี้ถูกใช้แล้ว กรุณาติดต่อ 02-0169950 หากต้องการความช่วยเหลือเพิ่มเติม'))
      } else {
        yield put(SetCodeValid(refcodeItem))
      }
    } else {
      throw new Error(data)
    }
  } catch (error) {
    yield put(
      popupError('ไม่พบรหัสดังกล่าวหรือพบปัญหาบางประการ กรุณาติดต่อ 02-0169950 หากต้องการความช่วยเหลือเพิ่มเติม')
    )
    console.log(error)
    //SET ERROR
  }
}

function* postRedeemToCore({ payload }) {
  try {
    const data = yield call(postToMondayRequest, payload)
    console.log(data)
    if (data) {
      yield put(setTicketData(data.data.create_item.id))

      if (data.data.create_item.id) {
        payload.ticketID = data.data.create_item.id

        yield put(popupSuccess())
      }
    } else {
      throw new Error(data.err_code)
    }
  } catch (error) {
    yield put(popupError('การส่งข้อมูลไม่สำเร็จ'))
  }
}

function* requestCodeWatcher() {
  yield takeEvery(CODE_POST_MONDAY, postToMonday)
}

function* validCodeWatcher() {
  yield takeEvery(CODE_GET_VALID, codeValidation)
}

function* requestRedeemCoreWatcher() {
  yield takeEvery(CODE_POST_REDEEM_CORE, postRedeemToCore)
}

export default function* rootSaga() {
  yield all([fork(requestCodeWatcher), fork(validCodeWatcher), fork(requestRedeemCoreWatcher)])
}
