import React, { Component, Suspense } from 'react'
import { connect } from 'react-redux'
import '../assets/css/app.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import { popupLoading, submitBookingMonday, submitToCore, popupError, GetCodeValid } from '../actions'
import { useTranslation, withTranslation, Translation } from 'react-i18next'

import axios from 'axios'

function addDays(date, days) {
  var result = new Date(date)
  result.setDate(result.getDate() + days)
  return result
}

class MainForm extends Component {
  constructor(props) {
    super(props)
  }

  formStyle = {
    borderRadius: '20px !important!important',
    paddingVertical: '5px',
    paddingHorizontal: '20px',
    border: '1px solid #da1a43',
    color: '000000',
  }
  codeStyle = {
    borderRadius: '0px 20px 20px 0px',
    paddingVertical: '5px',
    paddingHorizontal: '20px',
    border: '1px solid #da1a43',
    color: '000000',
  }

  componentDidMount() {
    if (localStorage.getItem('accesstokens')) {
      axios
        .post(
          process.env.REACT_APP_API_URL + process.env.REACT_APP_API_REFRESH,
          {},
          {
            headers: {
              Authorization: 'Bearer ' + localStorage.getItem('accesstokens'),
            },
          }
        )
        .then((res) => {
          localStorage.setItem('accesstokens', res.data.access_token)
          localStorage.setItem('expires_in', res.data.expires_in)
        })
        .catch((error) => {
          console.log(error)
          localStorage.removeItem('accesstokens')
          this.props.popupError(
            'ขออภัยคุณลูกค้าทุกท่าน ขณะนี้อยู่ในช่วงปรับปรุง กรุณาติดต่อไปยังเจ้าหน้าที่โดยตรง Phone: 02-016-9950  ขออภัยในความไม่สะดวกอย่างสูง'
          )
        })
    } else {
      axios
        .post(process.env.REACT_APP_API_URL + process.env.REACT_APP_API_OAUTH, {
          client_id: process.env.REACT_APP_MT_CLIENT_ID,
          channel_secret: process.env.REACT_APP_MT_KEYSEC_ID,
        })
        .then((res) => {
          localStorage.setItem('accesstokens', res.data.access_token)
          localStorage.setItem('expires_in', res.data.expires_in)
        })
        .catch((error) => {
          this.props.popupError(
            'ขออภัยคุณลูกค้าทุกท่าน ขณะนี้อยู่ในช่วงปรับปรุง กรุณาติดต่อไปยังเจ้าหน้าที่โดยตรง Phone: 02-016-9950  ขออภัยในความไม่สะดวกอย่างสูง'
          )
        })
    }
  }

  render() {
    return (
      <div class="p-4">
        <Translation>
          {(t, { i18n }) => {
            if (i18n.language === 'en') {
              return (
                <div>
                  <h3 class="mb-4 text-head">Terms &amp; Conditions</h3>
                  <div class="text-left">
                    <section>
                      <h1>Limousine booking - Terms and conditions</h1>
                      <ol>
                        <li>
                          We are pleased to offer our customers all-inclusive Net rates that cover First Class
                          insurance, fuel & mileage cost, toll fee (If any) and driver charges in THB currency.
                        </li>
                        <li>
                          For reservations and bookings, please contact us for service confirmation 48 hours in advance
                          prior engaging our service. Any booking/s made less than 48 hours prior to the commencement of
                          service shall be subjected to availability.
                        </li>
                        <li>
                          For any amendments or cancelations, it is compulsory to inform us at least 12 hours prior to
                          the pick-up time to avoid any penalty charge.
                        </li>
                        <li>
                          There will be a 100% no-show-charge for any last-minute cancelation less than 12 hours prior
                          to pick-up time.
                        </li>
                        <li>
                          <strong>Airport pick-up arrangement:</strong> Our driver will be on standby at the airport up
                          to 2 hours to pick-up our guest/s once the flight has landed safely at the airport. if our
                          airport representative could not find the guest/s or unable to reach out to guest/s’ via the
                          given contact information, the airport pick-up car will be released and it will be considered
                          as no-show with 100% no-show-charge directly to the guest/s account.
                        </li>
                        <li>
                          <strong>Other pick-up arrangement:</strong> Our driver will be waiting at the designated
                          pick-up location up to 30 minutes from the requested pick-up time. Additional charge on
                          waiting time beyond the 30-minute-mark applies at THB500/hour. Also, kindly be informed that
                          we are not responsible for any delayed requests from guest/s to request driver to depart later
                          than the pick-up time.
                        </li>
                        <li>
                          In the event of indirectly causing guest/s to miss the flight due to circumstances caused by
                          us, we will be responsible on compensating up to 10 times the cost of limousine service.
                        </li>
                        <li>
                          If guest/s would like to upgrade to another car types or travel out of the designated route,
                          additional fees will be charged directly to guest/s’ account according to our reservation
                          process.
                        </li>
                        <li>
                          Our sedan car can comfortably accommodate up to 3 passengers (2 standard-sized luggage and 2
                          carry-on bags are allowed per car). However, our sedan car is unable to accommodate wheelchair
                          service, therefore kindly request to upgrade to Van service upon reservation.
                        </li>
                        <li>
                          In the event of emergency situation where we cannot offer the requested car of choice on time
                          due to unforeseen circumstances such as accidents / heavy traffic / blocked or detour road /
                          flash flood / terrorist attacks etc., we will immediately offer a replacement car and inform
                          our guests accordingly; However, if the unforeseen emergency situation is beyond what we could
                          support and offer to our guests, we have the right to decline the service and will inform our
                          guest/s in advance.
                        </li>
                        <li>For any enquires or emergency matter, please dial +66(0)2016 9950</li>
                      </ol>
                      <br />
                    </section>
                    <section>
                      <h1>REPRESENTATIONS AND WARRANTIES</h1>
                      <div>
                        By using the Service or Application, You expressly agree, represent, and warrant to all of the
                        following:
                      </div>
                      <ol>
                        <li>You are at least 18 years old.</li>
                        <li>You have the right, authority and capacity to enter into this Agreement.</li>
                        <li>You abide by the terms and conditions of this Agreement.</li>
                        <li>You read, write and understand English.</li>
                        <li>You are using the Service or the App for your sole personal use.</li>
                        <li>You do not authorize others to use your user status.</li>
                        <li>
                          You will keep secure and confidential your username and account password or any identification
                          we provide you which allows access to the Service or the App.
                        </li>
                        <li>
                          You do not assign or otherwise transfer your user account to any other person or entity.
                        </li>
                        <li>
                          You comply with all applicable laws from the country, state and city in which you are present
                          while using the Application or Service.
                        </li>
                        <li>You only access the Service using authorized means.</li>
                        <li>
                          You are responsible to check and ensure that you download the correct Application for your
                          device.
                        </li>
                        <li>
                          The Company is not liable if you do not have a compatible handset or if you have downloaded
                          the wrong version of the Application for your handset.
                        </li>
                        <li>
                          The Company reserves the right to terminate this Agreement should you be using the Service or
                          Application with an incompatible or unauthorized device.
                        </li>
                        <li>
                          You will only use the Service or Application for lawful purposes; you will not use the
                          Services for sending or storing any unlawful material or for fraudulent purposes.
                        </li>
                        <li>You will not use or store in your user account any Counterfeit credit cards.</li>
                        <li>
                          You will not use the Service or Application to cause nuisance, annoyance or inconvenience.
                        </li>
                        <li>You will not impair the proper operation of the network.</li>
                        <li>You will not try to harm the Service or Application in any way whatsoever.</li>
                        <li>
                          You will not copy, or distribute the Application or other content without consent from the
                          Company.
                        </li>
                        <li>
                          You will only use the Application and Service for your own use and will not resell it to a
                          third party.
                        </li>
                        <li>You will provide us with whatever proof of identity we may reasonably request.</li>
                      </ol>
                      <br />
                    </section>
                    <section>
                      <h1>CONDUCT OF USERS</h1>
                      <div>
                        By entering into this Agreement or using the Application or the Service you agree that{' '}
                        <strong>(1)</strong> you will not misuse any third party car, use any third party car for any
                        illegal purpose, engage in any illegal activities (such as carrying any kind of drugs of
                        narcotics) in any third party car; and <strong>(2)</strong> you will comply with the laws of the
                        City and State where you use the App.
                      </div>
                      <br />
                    </section>
                    <section>
                      <h1>INDEMNIFICATION</h1>
                      <div>
                        By entering into this Agreement and using the Application or Service, you agree that you shall
                        defend, indemnify and hold the Company, its licensors and each such party’s parent
                        organizations, subsidiaries, affiliates, officers, directors, Users, employees, attorneys and
                        agents harmless from and against any and all claims, costs, damages, losses, liabilities and
                        expenses (including attorneys’ fees and costs) arising out of or in connection with: (a) your
                        violation or breach of any term of this Agreement or any applicable law or regulation, whether
                        or not referred to in this Agreement; (b) your violation of any rights of any third party,
                        including any providers of transportation services, or (c) your use or misuse of the Application
                        or Service.
                      </div>
                      <br />
                    </section>
                    <section>
                      <h1>NOTICE</h1>
                      <div>
                        The Company may give notice by means of a general notice on the Service, electronic mail to your
                        email address on record in the Company’s account information, or by written communication sent
                        by first class mail or pre-paid post to your address on record in the Company’s account
                        information. Such notice shall be deemed to have been given upon the expiration of 48 hours
                        after mailing or posting (if sent by first class mail or pre-paid post) or 12 hours after
                        sending (if sent by email). You may give notice to the Company (such notice shall be deemed
                        given when received by the Company) at any time by any of the following: letter delivered by
                        nationally recognized overnight delivery service or first class postage prepaid mail to the
                        Company.
                      </div>
                      <br />
                    </section>
                  </div>
                </div>
              )
            } else {
              return (
                <div>
                  <h3 class="mb-4 text-head">ข้อกําหนดและเงื่อนไข</h3>
                  <div class="text-left">
                    <section>
                      <h1>ข้อกำหนดและเงื่อนไขบริการรถรับหรือส่งสนามบิน</h1>
                      <ol>
                        <li>
                          อัตราค่าบริการนี้ เป็นค่าบริการสุทธิ รวมค่าประกันอุบัติเหตุ ค่าน้ำมัน ค่าทางด่วน (ถ้ามี)
                          และค่าคนขับรถแล้ว
                        </li>
                        <li>
                          กรุณานัดหมายล่วงหน้าอย่างน้อย 48 ชั่วโมงก่อนเข้ารับบริการ ในแบบฟอร์มการจองผ่านทางเว็บไซต์
                          https://booking.silver-voyage.com หรือ โทร 02-0169950 ระหว่างเวลา 09.00-21.00 น.ของทุกวัน
                          หรืออีเมล booking@silver-voyage.com ตลอด 24 ชั่วโมง กรณีที่นัดหมายน้อยกว่า 48
                          ชั่วโมงขึ้นอยู่กับความพร้อมของการบริการนั้นๆ เป็นรายกรณีไป
                        </li>
                        <li>
                          กรณียกเลิก หรือเปลี่ยนแปลงการนัดหมาย กรุณาแจ้งล่วงหน้าอย่างน้อย 12 ชั่วโมงก่อนการนัดหมาย
                          ที่เบอร์ 02-0169950 มิเช่นนั้น บริษัทฯ ขอสงวนสิทธิในการเรียกร้องความเสียหายที่เกิดขึ้น
                        </li>
                        <li>
                          สงวนสิทธิ์ในการยกเลิกหรือเปลี่ยนแปลงน้อยกว่าวันเวลาที่กำหนด
                          หากท่านไม่เข้ารับบริการตามวันเวลาดังกล่าวจะถือว่าท่านได้ใช้สิทธิ์ครบถ้วนแล้ว
                        </li>
                        <li>การจองจะสมบูรณ์ต่อเมื่อท่านได้รับ SMS หรืออีเมลยืนยันการจองที่สมบูรณ์</li>
                        <li>
                          <strong>การรอรับที่สนามบิน : </strong>เจ้าหน้าที่จะรอพบผู้โดยสารที่สนามบินสุวรรณภูมิ
                          อาคารผู้โดยสารขาเข้า ชั้น 2 ระหว่างประตู 3-4 หรือที่สนามบินดอนเมือง
                          อาคารผู้โดยสารขาเข้าเที่ยวบินต่างประเทศจุดนัดพบชั้น G ประตู 5
                          หรืออาคารผู้โดยสารขาเข้าเที่ยวบินในประเทศจุดนัดพบชั้น G ประตู 11 เป็นระยะเวลา 120 นาที
                          โดยเริ่มนับเวลาที่เครื่องบินมาถึงสนามบิน กรณีที่เจ้าหน้าที่ไม่พบผู้โดยสารและไม่สามารถติดต่อได้
                          ทางบริษัทฯ ขอสงวนสิทธิในการปล่อยรถ และคิดค่าบริการเต็มจำนวน
                          โดยจะถือว่าท่านได้ใช้สิทธิ์ครบถ้วนแล้ว{' '}
                        </li>
                        <li>
                          <strong>การรอรับจากที่พัก : </strong> พนักงานขับรถจะรอที่สถานที่นัดหมายเป็นเวลา 30 นาที
                          โดยเริ่มนับจากเวลานัดรับ หากพนักงานรอเกิน 30 นาที จะมีการคิดค่าธรรมเนียมเพิ่มเติมในอัตรา 500
                          บาทต่อชั่วโมง โดยเริ่มนับจากนาทีที่ 31 และทางบริษัทฯ
                          จะไม่รับผิดชอบในกรณีเกิดความล่าช้าที่เกี่ยวของกับการเดินทางทั้งทางบกและทางอากาศ
                        </li>
                        <li>
                          <strong>ประเภทรถยนต์ จำนวนผู้โดยสาร และสัมภาระสูงสุด: </strong> <br /> รถยนต์ Camry:
                          สามารถรองรับผู้โดยสาร 3 ท่านและกระเป๋าเดินทางขนาดใหญ่สูงสุด 2 ใบต่อคัน
                          <br />
                          รถยนต์ Fortuner: สามารถรองรับผู้โดยสาร 3 ท่านและกระเป๋าเดินทางขนาดใหญ่สูงสุด 2 ใบต่อคัน
                          <br />
                          รถยนต์ Hyundai H-1: สามารถรองรับผู้โดยสาร 6 ท่านและกระเป๋าเดินทางขนาดใหญ่สูงสุด 6 ใบต่อคัน
                          <br />
                          รถยนต์ Alphard: สามารถรองรับผู้โดยสาร 4 ท่านและกระเป๋าเดินทางขนาดใหญ่สูงสุด 4 ใบต่อคัน
                        </li>
                        <li>
                          กรณีเกิดเหตุสุดวิสัยและไม่สามารถจัดรถเข้ารับได้ตามกำหนดเวลา อันมีสาเหตุมาจากการเกิดอุบัติเหตุ,
                          การจราจรหนาแน่น, การปิดถนน, น้ำท่วม หรือการก่อการร้าย ทั้งนี้จะมีการจัดรถเข้ารับทดแทน
                          โดยจะแจ้งให้ทราบทันที
                        </li>
                        <li>
                          บริษัทฯ ขอสงวนสิทธิ์ในการเปลี่ยนแปลงเงื่อนไข ระยะเวลา และข้อกำหนดในการใช้บริการต่าง
                          ๆโดยไม่ต้องแจ้งให้ทราบล่วงหน้า{' '}
                        </li>
                        <li>กรุณาศึกษาข้อกำหนดและเงื่อนไขการใช้สิทธิพิเศษอย่างละเอียด </li>
                        <li>กรณีที่มีข้อโต้แย้งให้ถือเอาคำตัดสินของบริษัทฯ เป็นที่สุด</li>
                        <li>ท่านสามารถสอบถามเพิ่มเติมได้ที่เบอร์ 02-0169950</li>
                      </ol>
                      <br />
                    </section>
                    <section>
                      <h1>คำรับรอง </h1>
                      <div>ข้าพเจ้ายินยอมให้คำรับรองในการใช้บริการหรือแอปพลิเคชันทั้งหมดกับบริษัทฯ ดังต่อไปนี้:</div>
                      <ol>
                        <li>ผู้ใช้บริการมีอายุมากกว่า 18 ปีขึ้นไป</li>
                        <li>ข้าพเจ้ามีสิทธิและความสามารถในการทำข้อตกลงใดๆ ภายใต้ข้อกำหนดและเงื่อนไขข้องข้อตกลงนี้</li>
                        <li>ข้าพเจ้าตกลงที่จะปฏิบัติตามข้อกำหนดและเงื่อนไขของข้อตกลงนี้</li>
                        <li>ข้าพเจ้าสามารถอ่าน เขียนและเข้าใจภาษาไทยเป็นอย่างดี</li>
                        <li>ข้าพเจ้ากำลังใช้บริการหรือเว็บไซต์เพื่อการใช้งานส่วนตัวของข้าพเจ้า</li>
                        <li>ข้าพเจ้าไม่อนุญาตให้ผู้อื่นใช้บัญชีผู้ใช้บริการของข้าพเจ้า</li>
                        <li>
                          ข้าพเจ้าจะรักษาความปลอดภัยและความลับของชื่อบัญชีผู้ใช้บริการและรหัสผ่านบัญชีผู้ใช้บริการของข้าพเจ้า
                          หรือข้อมูลประจำตัวใด ๆ ที่ให้ไว้กับบริษัทฯ ซึ่งอนุญาตให้เข้าถึงบริการหรือแอปพลิเคชันของบริษัท
                          ฯ
                        </li>
                        <li>ข้าพเจ้าไม่ได้มอบหมาย หรือโอนบัญชีผู้ใช้บริการของท่านไปยังบุคคลอื่นหรือนิติบุคคลอื่น ๆ</li>
                        <li>
                          ข้าพเจ้าปฏิบัติตามกฎหมายที่บังคับใช้ทั้งหมดจากประเทศ
                          รัฐและเมืองที่ข้าพเจ้าอยู่ขณะใช้งานแอปพลิเคชั่นและบริการของบริษัท ฯ
                        </li>
                        <li>ข้าพเจ้าเข้าถึงบริการโดยใช้วิธีการที่ได้รับอนุญาตเท่านั้น</li>
                        <li>
                          ท่านมีหน้าที่ตรวจสอบและทำให้แน่ใจว่า ท่านดาวน์โหลดแอปพลิเคชันที่ถูกต้องสำหรับอุปกรณ์ของท่าน
                        </li>
                        <li>
                          บริษัทฯ ขอสงวนสิทธิที่จะไม่รับผิดชอบ
                          ในกรณีที่ท่านไม่มีโทรศัพท์มือถือที่ใช้งานร่วมกันกับระบบของบริษัท ฯ
                          หรือหากข้าพเจ้าดาวน์โหลดแอปพลิเคชันผิดเวอร์ชั่น
                        </li>
                        <li>
                          บริษัท ขอสงวนสิทธิ์ในการยกเลิกข้อตกลงนี้
                          ในกรณีที่ท่านใช้บริการหรือแอปพลิเคชันด้วยอุปกรณ์ที่เข้ากันไม่ได้หรือไม่ได้รับอนุญาต
                        </li>
                        <li>
                          ข้าพเจ้าจะใช้บริการหรือแอปพลิเคชันเพื่อจุดประสงค์ทางกฎหมายเท่านั้น
                          ข้าพเจ้าจะไม่ใช้บริการเพื่อส่งหรือจัดเก็บเนื้อหาที่ผิดกฎหมาย
                          หรือเพื่อวัตถุประสงค์ในการฉ้อโกงใดๆ
                        </li>
                        <li>ข้าพเจ้าจะไม่ใช้หรือจัดเก็บในบัญชีผู้ใช้ของข้าพเจ้าในบัตรเครดิตปลอม</li>
                        <li>
                          ข้าพเจ้าจะไม่ใช้บริการหรือแอปพลิเคชันเพื่อสร้างความรำคาญ การรบกวน หรือรบกวนความสะดวกแก่ผู้อื่น
                        </li>
                        <li>
                          ข้าพเจ้าจะไม่ทำให้การดำเนินการใดที่ทำให้เครือข่ายแอปพลิเคชั่น หรือเวปไซต์ของบริษัทฯ เสียหาย
                        </li>
                        <li>ข้าพเจ้าจะไม่พยายามทำลายบริการหรือแอปพลิเคชั่น แต่อย่างใด</li>
                        <li>
                          ข้าพเจ้าจะไม่ทำสำเนา หรือเผยแพร่แอปพลิเคชั่น หรือเนื้อหาใด ๆ โดยไม่ได้รับความยินยอมจากบริษัท ฯ
                        </li>
                        <li>
                          ข้าพเจ้าจะใช้แอปพลิเคชั่น หรือบริการจากบริษัท ฯ เพื่อใช้งานของข้าพเจ้าเองเท่านั้น
                          และจะไม่ขายหรือจำหน่ายต่อให้กับบุคคลที่สามแต่อย่างใด
                        </li>
                        <li>
                          ข้าพเจ้าจะให้ข้อมูลที่จำเป็นของข้าพเจ้า สำหรับการใช้บริการจากบริษัทฯ ตามที่บริษัทฯ ร้องขอ
                        </li>
                      </ol>
                      <br />
                    </section>
                    <section>
                      <h1>การควบคุมบัญชีผู้ใช้บริการ</h1>
                      <div>
                        ในการทำข้อตกลงนี้หรือใช้แอปพลิเคชั่นหรือบริการ ข้าพเจ้ายอมรับว่า <strong>(1)</strong>{' '}
                        ข้าพเจ้าจะไม่ใช้รถยนต์ของบุคคลที่สามในทางที่ผิดจากจุดประสงค์การให้บริการนี้
                        หรือใช้รถยนต์ของบุคคลที่สามเพื่อวัตถุประสงค์ที่ผิดกฎหมายใด ๆ เช่น ขนส่งยาเสพติด เป็นต้น และ
                        <strong>(2)</strong>ข้าพเจ้ารับทราบว่า
                        ข้าพเจ้าจะต้องปฏิบัติตามกฎหมายของประเทศที่ข้าพเจ้าใช้งานแอปพลิคชั่นนี้อยู่
                      </div>
                      <br />
                    </section>
                    <section>
                      <h1>การชดเชยความเสียหาย</h1>
                      <div>
                        ในการใช้งานแอปพลิเคชันหรือบริการ ข้าพเจ้ายอมรับว่า ข้าพเจ้าตกลงที่จะชดใช้ค่าเสียหาย ปกป้อง
                        และป้องกันบรรดาความเสียหายใดๆ แก่บริษัท ฯ (รวมถึงบริษัทในเครือ ผู้รับจ้างช่วง พนักงาน กรรมการ
                        ตัวแทน ผู้รับเหมา และคู่ค้าของบริษัท ฯ) ในการเรียกร้อง การฟ้องคดี การดำเนินคดี การสูญเสีย
                        ค่าใช้จ่าย ค่าเสียหาย ไม่ว่าจะส่วนหนึ่งหรือทั้งหมด และความรับผิดรูปในแบบอื่นใด
                        ซึ่งรวมถึงค่าทนายความที่เกิดจากบุคคลที่สามหรือเกี่ยวข้องกับ <strong> (ก) </strong>
                        การที่ข้าพเจ้าผิดข้อกำหนดและเงื่อนไขนี้ <strong> (ข) </strong>{' '}
                        การที่ข้าพเจ้าละเมิดสิทธิของบุคคลที่สาม รวมถึงผู้ให้บริการขนส่ง หรือ <strong>(ค)</strong>{' '}
                        การใช้หรือการใช้งานแอปพลิเคชันหรือบริการของบริษัท ฯ ในทางที่ผิด ทั้งนี้
                        บริษัทขอสงวนสิทธิ์ในการเรียกร้องความเสียหายที่เกิดขึ้นจากท่านแต่เพียงผู้เดียตามขอบเขตสูงสุดที่กฎหมายอนุญาต
                        และจะมีผลไปตลอดข้อกำหนดและเงื่อนไขฉบับนี้ และตลอดการใช้บริการของข้าพเจ้า
                      </div>
                      <br />
                    </section>
                    <section>
                      <h1>หมายเหตุ</h1>
                      <div>
                        บริษัทฯ อาจบอกกล่าว แจ้งข้อความ หรือประกาศใดๆ เกี่ยวกับบริการของบริษัทฯ ไปยังอีเมล
                        หมายเลขโทรศัพท์ หรือที่อยู่ของท่านทางไปรษณีย์ ตามที่ท่านได้เคยให้ไว้กับบริษัทฯ ทั้งนี้ บริษัท ฯ
                        จะถือว่า ท่านจะได้รับทราบการบอกล่าวเมื่อครบกำหนด 48 ชั่วโมงหลังจากที่บริษัท ฯ
                        ส่งจดหมายทางไปรษณีย์ไปหาท่าน หรือ 12 ชั่วโมงหลังจากที่บริษัท ฯ
                        ส่งการบอกล่าวนั้นไปยังที่อยู่อีเมล หรือหมายเลขโทรศัพท์ของท่าน{' '}
                      </div>
                      <br />
                    </section>
                  </div>
                </div>
              )
            }
          }}
        </Translation>
      </div>
    )
  }
}
const mapStateToProps = ({ coupon, popup }) => {
  return { coupon, popup }
}

export default connect(mapStateToProps, {
  popupLoading,
  popupError,
  submitBookingMonday,
  submitToCore,
  GetCodeValid,
})(MainForm)
