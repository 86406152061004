import React, { Component } from 'react'

import '../../assets/css/app.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import { Link } from 'react-router-dom'

class Footer extends Component {
  render() {
    return (
      <div>
        <footer className="container-fluid">
          <div className="row">
            <div class="col-6 col-md-5 bg-grey">
              <Link to="/term">
                <div class="p-2 text-center">
                  <a class="text-white"> Terms & Conditions </a>
                </div>
              </Link>
            </div>
            <div className="col col-md text-right bg-red">
              <div className="p-2 text-center text-white">
                Copyright &copy;2019
                <span className="d-md-inline"> Silver Voyage </span>
                <span className="d-sm-inline"> managed by World Reward Solutions.</span>
              </div>
            </div>
          </div>
        </footer>
      </div>
    )
  }
}

export default Footer
