import { combineReducers } from 'redux'
import { reducer as reduxForm } from 'redux-form'
import AppStageReducer from './AppStageReducer'
import CouponReducer from './CouponReducer'
import PopupReducer from './PopupReducer'

const reducers = combineReducers({
  form: reduxForm,
  appStage: AppStageReducer,
  coupon: CouponReducer,
  popup: PopupReducer,
})
console.log(process.env)
export default reducers
