import React, { Component } from 'react'

import '../../assets/css/app.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import { Link } from 'react-router-dom'
class SideMenu extends Component {
  render() {
    return (
      <div className="desktop-side-bg nav-main d-none d-md-block col-md-7 p-3 text-center">
        <Link to="/">
          {' '}
          <a className="py-3 mr-3 d-inline-block " href="#section">
            Home
          </a>
        </Link>
        <br />
        <Link to="/">
          {' '}
          <a className="py-3 mr-3 d-inline-block " href="#section">
            <span>Limousine</span> Service
          </a>
        </Link>
        <br />
      </div>
    )
  }
}

export default SideMenu
