import React, { Component, Suspense } from 'react'
import { useTranslation, withTranslation, Translation } from 'react-i18next'
import '../../assets/css/app.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPhone, faClock, faEnvelope } from '@fortawesome/free-solid-svg-icons'

class Header extends Component {
  render() {
    return (
      <div>
        <header className="px-3 py-2 d-none d-md-flex text-white">
          <div className="mr-auto">
            <span className="pr-4">
              <FontAwesomeIcon icon={faPhone} /> Phone: 02-016-9950{' '}
            </span>{' '}
            <span className="pr-4">
              <FontAwesomeIcon icon={faEnvelope} /> booking@silver-voyage.com{' '}
            </span>{' '}
            <span>
              <FontAwesomeIcon icon={faClock} /> Everyday: 24 Hours{' '}
            </span>{' '}
          </div>{' '}
          <span className="pull-right">
            {' '}
            <Translation>{(t, { i18n }) => <i onClick={() => i18n.changeLanguage('th')}>TH</i>}</Translation>/
            <Translation>{(t, { i18n }) => <i onClick={() => i18n.changeLanguage('en')}>EN</i>}</Translation>
          </span>{' '}
        </header>{' '}
        <div className="red-gradient"> </div>{' '}
      </div>
    )
  }
}

export default Header
