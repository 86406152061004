import React, { Component, Suspense } from 'react'
import { connect } from 'react-redux'

import '../assets/css/app.css'
import 'bootstrap/dist/css/bootstrap.min.css'

import Header from './Header/Header'
import Footer from './Footer/Footer'
import TermModal from './Modal/termModal'
import MainForm from './Form/mainfrom'
import Term from './term'
import SideMenu from './Menu/sidemenu'
import MobileMenu from './Menu/mobilemenu'
import ConsentModal from './Modal/consentModal'
import SuccessModal from './Modal/successModal'
import LockAppModal from './Modal/lockAppModal'
import PopupBackdrop from './Modal/PopupBackdrop'
import PopupLoading from './Modal/PopupLoading'
import PopupNotice from './Modal/PopupNotice'
import PopupError from './Modal/PopupError'
import { popupHidden, lockAppPopup } from '../actions'
import './../assets/css/Loading.css'
import { I18nextProvider } from 'react-i18next'
import i18n from '../util/i18n'

import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom'

class App extends Component {
  constructor(props) {
    super(props)
    this.state = { activeTab: 3 }
  }

  render() {
    const popup = this.props.popup
    const resultTicket = this.props.coupon.ticketId

    const dataprop = [
      this.props.coupon.mondayModel,
      this.props.coupon.carMaxDetail,
      this.props.coupon.car_type,
      this.props.coupon.car_pic,
    ]

    console.log(popup.display)
    return (
      <Router>
        <div>
          <div className="wrapper-content d-flex w-100 h-100 mx-auto flex-column">
            <Suspense
              fallback={
                <div className="lds-ellipsis">
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
              }
            >
              <Header />
            </Suspense>
            <main className="container-fluid">
              <PopupError
                display={popup.display === 'error'}
                msg={popup.msg}
                closePopup={() => this.props.popupHidden()}
              />
              <SuccessModal
                display={popup.display === 'success'}
                closePopup={() => this.props.popupHidden()}
                ticketId={resultTicket}
              />
              <PopupNotice
                display={popup.display === 'startup'}
                closePopup={() => this.props.popupHidden()}
                lockAppPopup={() => this.props.lockAppPopup()}
              />
              <PopupLoading display={popup.display === 'loading'} />
              <ConsentModal display={popup.display === 'consent'} closePopup={() => this.props.popupHidden()} />
              <LockAppModal display={popup.display === 'lock'} />

              <PopupBackdrop display={popup.display !== 'hidden'} />
              <div className="row" id="page-limousineService">
                <Suspense
                  fallback={
                    <div className="lds-ellipsis">
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                    </div>
                  }
                >
                  <MobileMenu />
                </Suspense>

                <div className="col-md-5 text-center pb-4" id="main-content">
                  <Suspense
                    fallback={
                      <div className="lds-ellipsis">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                      </div>
                    }
                  >
                    <Switch>
                      <Route path="/term">
                        <I18nextProvider i18n={i18n}>
                          <Term i18n={i18n} />
                        </I18nextProvider>
                      </Route>
                      <Route path="/">
                        <MainForm data={dataprop} />
                      </Route>
                    </Switch>
                  </Suspense>
                </div>
                <SideMenu />
              </div>
            </main>
            <Footer />
          </div>
        </div>
      </Router>
    )
  }
}

const mapStateToProps = ({ coupon, popup }) => {
  return { coupon, popup }
}

export default connect(mapStateToProps, {
  popupHidden,
  lockAppPopup,
})(App)
