import React, { Component } from 'react'

import '../../assets/css/app.css'
import 'bootstrap/dist/css/bootstrap.min.css'

class TermModal extends Component {
  render() {
    return (
      <div>
        <div
          className="modal"
          id="consent_form"
          tabIndex="999999"
          role="dialog"
          aria-labelledby="consent_form_title"
          aria-hidden="true"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="detail-1">
                <div className="modal-header">
                  <h5 className="modal-title" id="consent_form_title">
                    ข้อกำหนดและเงื่อนไขการใช้บริการสิทธิพิเศษ
                  </h5>
                </div>

                <div className="modal-body">
                  ในการใช้สิทธิพิเศษนี้ ข้าพเจ้าตกลงและให้ความยินยอมดังต่อไปนี้ <br />
                  <br />
                  <ol>
                    <li>
                      ข้าพเจ้ารับทราบและตกลงว่าบริษัท เวิลด์ รีวอร์ด โซลูชั่น จำกัด (“<b>บริษัทฯ</b>”)
                      เป็นผู้ดำเนินการจัดการสิทธิพิเศษที่ข้าพเจ้าได้รับจากธนาคารเกียรตินาคิน จำกัด (มหาชน) (“
                      <b>ธนาคารฯ</b>”)ตามเงื่อนไขที่ธนาคารฯ กำหนด ทั้งนี้ ธนาคารฯ
                      เป็นแต่เพียงผู้จัดหาสิทธิพิเศษให้แก่ข้าพเจ้าเท่านั้น
                      ไม่ได้มีส่วนเกี่ยวข้องกับการจัดการและให้บริการสิทธิพิเศษของบริษัทฯ แต่อย่างใด
                    </li>
                    <br />
                    <li>
                      ข้าพเจ้ารับทราบ ตกลง และยินยอมว่าการให้ข้อมูลสำหรับการใช้บริการสิทธิพิเศษนี้ เป็นการให้แก่บริษัทฯ
                      และข้อมูลดังกล่าวจะถูกนำไปใช้ เก็บ และ/หรือ รวบรวม โดยบริษัทฯ
                      และ/หรือผู้ที่เกี่ยวข้องในการดำเนินการให้สิทธิพิเศษนี้
                    </li>
                    <br />
                    <li>
                      ข้าพเจ้ารับทราบ ตกลง และยินยอมว่าบริษัทฯ สามารถนำข้อมูลที่ข้าพเจ้าได้ให้ไว้แก่บริษัทฯ
                      ไปเปิดเผยแก่บุคคลอื่นใดที่เกี่ยวข้องในการดำเนินการให้บริการสิทธิพิเศษ รวมถึงตกลง
                      และยินยอมว่าบริษัทฯ สามารถจัดส่งข้อมูลดังกล่าวให้กับธนาคารฯ เพื่อประโยชน์ในการดำเนินการของธนาคารฯ
                      ด้วย ทั้งนี้
                      การดำเนินการดังกล่าวต้องไม่เป็นการกระทบต่อสิทธิความเป็นเจ้าของข้อมูลส่วนบุคคลของข้าพเจ้าเกินสมควร
                    </li>
                    <br />
                    <li>
                      ข้าพเจ้ารับทราบว่าธนาคารฯ เป็นเพียงผู้จัดหาสิทธิพิเศษให้แก่ข้าพเจ้าเท่านั้น
                      ไม่ได้มีส่วนเกี่ยวข้องกับการให้บริการสิทธิพิเศษของบริษัทฯ แต่อย่างใด
                      กรณีเกิดความเสียหายและ/หรือสูญหายอย่างใดๆ ข้าพเจ้าตกลงดำเนินการเรียกร้องกับบริษัทฯ ทั้งสิ้น
                    </li>
                    <br />
                    <li>
                      ข้าพเจ้าตกลงและยินยอมให้ธนาคารฯ เปิดเผย และ/หรือนำส่งข้อมูลของข้าพเจ้าที่ธนาคารฯ เห็นสมควร
                      แก่บริษัทฯ เพื่อประโยชน์ในการใช้สิทธิพิเศษ ตรวจสอบการใช้สิทธิพิเศษ และ
                      /หรือดำเนินการอื่นใดที่เกี่ยวข้องกับการใช้สิทธิพิเศษของข้าพเจ้า
                    </li>
                  </ol>
                </div>
                <div className="modal-footer text-center d-block pt-0">
                  <button type="button" className="btn btn-primary btn-md button_agree_term" data-dismiss="modal">
                    ยอมรับข้อกำหนดและเงื่อนไข
                  </button>
                </div>
                <div className="modal-footer text-center d-block pt-0">
                  <button type="button" className="btn btn-primary btn-md">
                    ยกเลิก
                  </button>
                </div>
              </div>
              <div className="detail-2">
                <div className="modal-header">
                  <h5 className="modal-title" id="consent_form_title">
                    ติดต่อสอบถาม
                  </h5>
                </div>
                <div className="modal-body text-center">
                  <p>
                    <span>
                      <i className="icon-envelope"></i> booking@silver-voyage.com
                    </span>
                    <br />
                    <i className="icon-phone"></i> Phone: 02-016-9950
                    <br />
                    <i className="icon-clock"></i> Everyday: 24 Hours
                    กรณีฉุกเฉินหรือต้องการความช่วยเหลือเร่งด้วยสามารถติดต่อได้ตลอด 24 ชั่วโมง
                  </p>
                </div>
                <div className="modal-footer text-center d-block pt-0">
                  <button type="button" className="btn btn-primary btn-md">
                    กลับสู่หน้าข้อกำหนดและเงื่อนไข
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default TermModal
