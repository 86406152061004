import React, { Component, Suspense } from 'react'
import { connect } from 'react-redux'
import '../../assets/css/app.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import styles from '../../assets/css/form-stylesheet.css' // Import css modules stylesheet as styles
import { popupLoading, submitBookingMonday, submitToCore, popupError, GetCodeValid } from '../../actions'
import { useTranslation, withTranslation, Translation } from 'react-i18next'
import DatePicker from 'react-datepicker'
import logo from '../../assets/imgs/logo2.png'
import sha from '../../assets/imgs/sha.png'
import alpardCar from '../../assets/imgs/home/alphard-.png'
import allCar from '../../assets/imgs/home/5car.png'
import camryCar from '../../assets/imgs/camry-01.png'
import benzCar from '../../assets/imgs/benz.png'
import bmwCar from '../../assets/imgs/bmw-01.png'
import h1Car from '../../assets/imgs/hyundai-01.png'
import 'react-datepicker/dist/react-datepicker.css'
import axios from 'axios'

function addDays(date, days) {
  var result = new Date(date)
  result.setDate(result.getDate() + days)
  return result
}

var TimeoutToValidCoupon

var camrySetting = { name: 'Camry/Fortuner', price: 1300 }
var H1Setting = { name: 'H1', price: 2200 }
var AlphardSetting = { name: 'Alphard', price: 3500 }
var BenzSetting = { name: 'Benz E-Class', price: 3500 }
var ToyataMajestySetting = { name: 'Toyata Majesty', price: 2200 }
var PhuketSetting = { name: 'PHUKET EV CAR', price: 1200 }

class MainForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      firstname: '',
      lastname: '',
      phone: '',
      email: '',
      bookingTime: '',
      person: '',
      luggage: '',
      car_type: '1',
      car_pic: 5,
      carArrayMapCoupon: ['', '', '', '', '', '', '', '', '', '', '', '', '', '', '', ''],
      carArray: [
        '',
        camrySetting,
        '',
        H1Setting,
        AlphardSetting,
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        ToyataMajestySetting,
        // PhuketSetting,
      ],
      carArr: [],
      pick_upAddress: '',
      Message: '',
      pick_up_interest: '',
      pick_upFlight: '',
      pick_up_airport: '1',
      drop_offAddress: '',
      drop_off_interest: '',
      drop_off_airport: '1',
      drop_offFlight: '',
      maxPAX: '',
      note: '',
      remark: '',
      maxPerson: '',
      flagValidCar: true,
      data: this.props.data,
      coupon: this.props.coupon,
      code: '',
      selectedOption: null,
      pickupType: 0,
      dropoffType: 0,
      startDate: new Date(),
      Datetime: '',
      privilege_id: '',
      coupon_id: '',
      disabled: false,
      valid: false,
      selectedCoupon: '',
      discountType: '',
      discountValue: 0,
    }
    this.onchangeDate = this.onchangeDate.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  formStyle = {
    borderRadius: '20px !important!important',
    paddingVertical: '5px',
    paddingHorizontal: '20px',
    border: '1px solid #da1a43',
    color: '000000',
  }
  codeStyle = {
    borderRadius: '0px 20px 20px 0px',
    paddingVertical: '5px',
    paddingHorizontal: '20px',
    border: '1px solid #da1a43',
    color: '000000',
  }

  componentDidMount() {
    if (localStorage.getItem('accesstokens')) {
      axios
        .post(
          process.env.REACT_APP_API_URL + process.env.REACT_APP_API_REFRESH,
          {},
          {
            headers: {
              Authorization: 'Bearer ' + localStorage.getItem('accesstokens'),
            },
          }
        )
        .then((res) => {
          localStorage.setItem('accesstokens', res.data.access_token)
          localStorage.setItem('expires_in', res.data.expires_in)
        })
        .catch((error) => {
          console.log(error)
          localStorage.removeItem('accesstokens')
          this.props.popupError(
            'ขออภัยคุณลูกค้าทุกท่าน ขณะนี้อยู่ในช่วงปรับปรุง กรุณาติดต่อไปยังเจ้าหน้าที่โดยตรง Phone: 02-016-9950  ขออภัยในความไม่สะดวกอย่างสูง'
          )
        })
    }
    // else  {
    //   axios
    //     .post(process.env.REACT_APP_API_URL  + process.env.REACT_APP_API_OAUTH , {
    //       client_id: process.env.REACT_APP_MT_CLIENT_ID,
    //       channel_secret: process.env.REACT_APP_MT_KEYSEC_ID,
    //     })
    //     .then(res => {
    //       localStorage.setItem('accesstokens', res.data.access_token)
    //       localStorage.setItem('expires_in', res.data.expires_in)
    //     })
    //     .catch (error =>{

    //       this.props.popupError('ขออภัยคุณลูกค้าทุกท่าน ขณะนี้อยู่ในช่วงปรับปรุง กรุณาติดต่อไปยังเจ้าหน้าที่โดยตรง Phone: 02-016-9950  ขออภัยในความไม่สะดวกอย่างสูง')
    //     } )

    // }

    this.setState({ carArr: this.state.carArray })
  }

  componentDidUpdate() {
    var allCoupon = this.props.coupon.validationCodeFromServer

    if (this.state.valid && allCoupon.length > 0 && allCoupon !== undefined) {
      let carAvaliable = []
      let car_typeNO = []
      let carArray = this.state.carArray
      let copyItemState = this.state.carArrayMapCoupon
      // map รถแต่ละประเภทลงไปเก็บ
      let allCoupons = allCoupon.map((coupon) => {
        console.log(coupon)
        let car = JSON.parse(coupon.dropdown1)
        copyItemState[car.ids[0] !== undefined ? car.ids[0] : 'All'] = coupon
        return coupon
      })

      // เลือกชื่อรถแต่ละประเภทมา 1 คัน
      carAvaliable = copyItemState
        .map((carChoosed) => {
          if (carChoosed !== '') {
            let car = JSON.parse(carChoosed.dropdown1)
            if (car.ids[0] > 0) {
              car_typeNO.push(car.ids[0])
            } else {
              return false
            }

            return carArray[car.ids[0]]
          } else {
            return false
          }
        })
        .filter((car) => car !== false)
      console.log(allCoupons)
      if (allCoupons.length > 0) {
        this.setState({ selectedCoupon: allCoupons[0] })
      }
      let netCost = this.state.carArray[parseInt(car_typeNO[0] ? String(car_typeNO[0]) : '1')].price
      if (carAvaliable.length > 0 || copyItemState) {
        this.setState({
          flagValidCar: true,
          valid: false,
          car_type: car_typeNO[0] ? String(car_typeNO[0]) : '1',
          carArr: carAvaliable.length > 0 ? carAvaliable : this.state.carArray,
          selectedCoupon: allCoupons[0],
          carArrayMapCoupon: copyItemState,
        })

        if (allCoupons[0].couponType === 'DISCOUNT') {
          if (allCoupons[0].discountType === 'PERCENT') {
            // percent
            netCost = netCost - netCost * (allCoupons[0].discountValue / 100)
          } else if (allCoupons[0].discountType === 'VALUE') {
            // vaule
            netCost = netCost - allCoupons[0].discountValue
          }
        } else if (allCoupons[0].couponType === 'COUPON') {
        }
      }

      // calculate price

      this.setState({ netPrice: netCost })
    }
  }

  onchangeDate(date) {
    this.setState({
      Datetime: date,
    })
  }

  handleInputChange(value, name) {
    // Booking Time Formatter
    try {
      this.setState({ [name]: value })
      let val = ''
      if (name == 'bookingTime') {
        val = value + ''
        let numberCode = val.match('[0-9]+')
        if (val.length === 2 && numberCode[0].length === 2) {
          val += ':'
        }

        this.setState({ [name]: val.substring(0, 5) })
      }
    } catch (e) {
      console.log(e)
    }
  }

  returnMsgCar(car_type, lng, type) {
    // Hard code Message

    let car_typeNo = car_type
    if (car_type === '1') car_type = 'CAMRY'
    else if (car_type === '3') car_type = 'H1'
    else if (car_type === '4') car_type = 'ALPHARD'
    else if (car_type === '6') car_type = 'BENBMW'
    else if (car_type === '14') car_type = 'TOYOTA'
    else if (car_type === '15') car_type = 'PHUKET_EV'
    if (car_type == '') return ''
    if (car_type == 'BENZ E CLASS' || car_type == 'BMW Series 5') car_type = 'BENBMW'
    let carMsg = this.state.data[1][car_type].Msg
    let Msg = ''
    if (lng == 'en')
      return (
        Msg +
        ' Max ' +
        this.state.data[1][car_type].person +
        ' person and ' +
        this.state.data[1][car_type].pax +
        ' luggage'
      )
    if (lng == 'th')
      return (
        Msg +
        ' สูงสุด ' +
        this.state.data[1][car_type].person +
        ' ท่านและกระเป๋าสูงสุด ' +
        this.state.data[1][car_type].pax +
        ' ใบ'
      )
    if (type === 'p') return this.state.data[1][car_type].person
    if (type === 'l') return this.state.data[1][car_type].pax
  }

  onChangeLimousineCode(value) {
    // Code valid
    //In next version : Have to remove hardcode privilege data
    try {
      let strValue = String(value).toUpperCase()
      let prefixCode = []
      let postfixCode = []
      let numberCode = []

      if (strValue == '') {
        this.setState({ code: '' })
      } else {
        this.setState({ code: strValue })
        prefixCode = strValue.match('[A-Z]+')
        postfixCode = strValue.replace(prefixCode[0], '').match('[A-Z]+')
        numberCode = strValue.match('[0-9]+')
        this.setState({ car_pic: 5 })
      }

      //  if(prefixCode[0] === "CIGNA" && postfixCode[0] === "WRS" && numberCode[0].length > 0){
      //     this.setState({
      //       flagValidCar: false,
      //     })
      //     if(numberCode[0].length === 7) {
      //      this.props.GetCodeValid(strValue.toUpperCase())
      //       this.setState({
      //         flagValidCar: true
      //       })
      //     }
      //   }
      //   else

      if (strValue == '') {
        this.setState({
          flagValidCar: true,
        })
        this.setState({ car_pic: 5, carArr: this.state.carArray })

        this.setState({ code: '' })
      } else {
        this.setState({ car_pic: 5 })
        this.setState({
          flagValidCar: false,
        })
      }
    } catch (e) {
      console.log(e)
    }
  }

  checkCodeManual() {
    //done : prevent blank code
    if (this.state.code !== '') {
      this.props.GetCodeValid(this.state.code)
    }
  }

  handleSubmit(e) {
    e.preventDefault()
    let status = ''
    let itemId = '-'
    console.log(this.state.selectedCoupon)
    debugger
    if ((!this.state.flagValidCar && this.state.code !== '') || this.state.selectedCoupon == undefined) {
      this.props.popupError('กรุณากรอกรหัสสิทธิพิเศษให้ถูกต้อง กรุณาติดต่อ 02-0169950 หากต้องการความช่วยเหลือเพิ่มเติม')

      return
    }

    if (this.state.selectedCoupon !== '' && this.state.selectedCoupon !== undefined && this.state.code !== '') {
      status = JSON.parse(this.state.selectedCoupon.status)
      itemId = this.state.selectedCoupon.id
    }
    console.log(this.state.selectedCoupon)
    if (this.state.selectedCoupon === '') {
      this.props.popupError('กรุณากรอกรหัสสิทธิพิเศษให้ถูกต้อง กรุณาติดต่อ 02-0169950 หากต้องการความช่วยเหลือเพิ่มเติม')
    } else if (status.index === 2 || this.state.selectedCoupon.length == 0) {
      // pin into note ว่าใช้แล้ว
      this.props.popupError(
        'รหัส ' + this.state.code + ' นี้ถูกใช้แล้ว กรุณาติดต่อ 02-0169950 หากต้องการความช่วยเหลือเพิ่มเติม'
      )
      //this.props.popupLoading()
      // this.props.submitBookingMonday(this.state.code, this.state)
    } else if (status.index === 0) {
      this.props.popupError(
        'ไม่สามารถใช้สิทธิพิเศษนี้ได้เนื่องจากรหัสถูกยกเลิกการใช้งาน กรุณาติดต่อ 02-0169950 หากต้องการความช่วยเหลือเพิ่มเติม'
      )
    }

    this.props.popupLoading()
    this.setState({ disabled: true })
    this.props.submitBookingMonday(this.state.code, this.state, itemId)
  }

  componentWillReceiveProps(newProps) {
    this.setState({ valid: true })

    this.setState({ coupon: newProps.coupon })
  }

  render() {
    const car_pic = this.state.car_pic
    const carPicArr = [h1Car, camryCar, benzCar, bmwCar, alpardCar, allCar]

    console.log(this.state.selectedCoupon)

    return (
      <div style={{ maxWidth: '340px', margin: 'auto' }}>
        <img src={logo} className="pt-2 px-2" alt="logo" style={{ width: '100px', margin: 'auto' }} />
        <img src={sha} className="pt-2  px-2" alt="logo" style={{ width: '100px', margin: 'auto' }} />
        <h1 className="pt-2">
          {' '}
          <img src={carPicArr[car_pic]} alt="LIMOUSINE" style={{ width: '230px' }} />{' '}
        </h1>
        <h2 className="text-head">
          <span>Limousine</span> Service
        </h2>
        <div className="text-center" style={{ color: '#999', margin: 'auto', maxWidth: '360px', fontSize: '12px' }}>
          TO DELIVER EXCELLENT SERVICE AT AN UNBEATABLE PRICE,
          <br />
          THAT WOULD SATISFY YOU WITH OUR PROFESSIONALISM
        </div>
        <form onSubmit={this.handleSubmit}>
          <div id="step-1" className="step pt-2">
            <div className="row pt-2">
              <div className="form-group col">
                <div className="input-group">
                  <Translation>
                    {(t, { i18n }) => (
                      <input
                        type="text"
                        id="code-box"
                        style={{ borderRadius: '0px 20px 20px 0px' }}
                        name="code"
                        className="form-control "
                        placeholder={t('code')}
                        onChange={(e) => this.onChangeLimousineCode(e.target.value)}
                        value={this.state.code}
                      />
                    )}
                  </Translation>
                  <div class="input-group-append">
                    <button
                      type="button"
                      style={{ display: 'block' }}
                      className="btn btn-check"
                      onClick={this.checkCodeManual.bind(this)}
                    >
                      Enter
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div className="row" style={this.state.flagValidCar ? { display: 'block' } : { display: 'none' }}>
              <div className="form-group col">
                <Translation>
                  {(t, { i18n }) => (
                    <textarea
                      rows="3"
                      className="form-control"
                      name="textarea"
                      id="textarea"
                      placeholder={this.returnMsgCar(this.state.car_type, i18n.languages[0])}
                      disabled
                      style={this.formStyle}
                    ></textarea>
                  )}
                </Translation>
              </div>
            </div>

            <div className="row" style={{ display: 'block' }}>
              <div className="form-group col">
                <select
                  name="car_type"
                  id="car_type"
                  className="form-control"
                  style={this.formStyle}
                  onChange={(evt) => {
                    this.setState({ car_type: evt.target.value })
                    if (this.state.carArrayMapCoupon.All) {
                      this.setState({ selectedCoupon: this.state.carArrayMapCoupon.All })
                    } else {
                      this.setState({ selectedCoupon: this.state.carArrayMapCoupon[evt.target.value] })
                    }

                    if (this.state.netPrice) {
                      let netCost = this.state.carArray[parseInt(evt.target.value)].price

                      console.log(this.state)
                      if (this.state.netPrice) {
                        if (this.state.selectedCoupon.couponType === 'DISCOUNT') {
                          if (this.state.selectedCoupon.discountType === 'PERCENT') {
                            // percent
                            netCost = netCost - netCost * (this.state.selectedCoupon.discountValue / 100)
                          } else if (this.state.selectedCoupon.discountType === 'VALUE') {
                            // vaule
                            netCost = netCost - this.state.selectedCoupon.discountValue
                          }
                        } else if (this.state.selectedCoupon.couponType === 'COUPON') {
                        }
                      }

                      this.setState({ netPrice: netCost })
                    }
                  }}
                >
                  {this.state.carArr.map((val, index) => {
                    let carAvaliableForRender = val.name + ' - ' + val.price + ' Baht/Way'
                    let keyIndex = this.state.carArray.findIndex((item) => item.name === val.name)
                    if (val != '' && keyIndex >= 0) {
                      return (
                        <option key={keyIndex} value={keyIndex}>
                          {carAvaliableForRender}
                        </option>
                      )
                    }
                  })}
                </select>
              </div>
              <div style={{ color: '#999' }}>
                Price before discount for service area within Bangkok, Pathum Thani, Nonthaburi, Samut Prakan.
              </div>

              <div style={{ display: this.state.netPrice ? 'block' : 'none' }}>
                <h2>Net pay : {this.state.netPrice} Bath</h2>
              </div>

              <br />
              <br />
            </div>
            <h5 className="mb-3">
              <Translation>{(t, { i18n }) => t('Personalinformation')}</Translation>
            </h5>
            <div className="row pt-1">
              <div className="form-group col">
                <Translation>
                  {(t, { i18n }) => (
                    <input
                      type="text"
                      id="firstname"
                      name="firstname"
                      className="form-control"
                      style={this.formStyle}
                      placeholder={t('firstname')}
                      onChange={(e) => this.handleInputChange(e.target.value, 'firstname')}
                      value={this.state.firstname}
                      required
                    />
                  )}
                </Translation>
              </div>
              <div className="form-group col">
                <Translation>
                  {(t, { i18n }) => (
                    <input
                      type="text"
                      id="lastname"
                      name="lastname"
                      className="form-control"
                      style={this.formStyle}
                      placeholder={t('lastname')}
                      onChange={(e) => this.handleInputChange(e.target.value, 'lastname')}
                      value={this.state.lastname}
                      required
                    />
                  )}
                </Translation>
              </div>
            </div>
            <div className="row">
              <div className="form-group col">
                <Translation>
                  {(t, { i18n }) => (
                    <input
                      type="text"
                      id="mobile"
                      name="mobile"
                      className="form-control"
                      style={this.formStyle}
                      placeholder={t('phone')}
                      onChange={(e) => this.handleInputChange(e.target.value, 'phone')}
                      value={this.state.phone}
                      required
                    />
                  )}
                </Translation>
              </div>
            </div>
            <div className="row">
              <div className="form-group col">
                <Translation>
                  {(t, { i18n }) => (
                    <input
                      type="text"
                      id="email"
                      name="email"
                      className="form-control"
                      style={this.formStyle}
                      placeholder={t('email')}
                      onChange={(e) => this.handleInputChange(e.target.value, 'email')}
                      value={this.state.email}
                    />
                  )}
                </Translation>
              </div>
            </div>

            <div className="row">
              <div className="form-group col">
                <Translation>
                  {(t, { i18n }) => (
                    <DatePicker
                      id="datepicker"
                      className="form-control"
                      dateFormat="dd/MM/yyyy"
                      placeholderText={t('BookingDate')}
                      style={{ width: '100% !important' }}
                      selected={this.state.Datetime}
                      minDate={addDays(new Date(), 2)}
                      onChange={this.onchangeDate}
                      required
                    />
                  )}
                </Translation>
              </div>
            </div>
            <div className="row">
              <div className="form-group col">
                <Translation>
                  {(t, { i18n }) => (
                    <input
                      type="text"
                      id="booking_time"
                      name="booking_time"
                      className="form-control"
                      style={this.formStyle}
                      pattern="[0-9]{2}:[0-9]{2}"
                      placeholder={t('BookingTime')}
                      onChange={(e) => this.handleInputChange(e.target.value, 'bookingTime')}
                      value={this.state.bookingTime}
                      required
                    />
                  )}
                </Translation>
              </div>
            </div>
            <div className="row">
              <div className="form-group col">
                <Translation>
                  {(t, { i18n }) => (
                    <input
                      type="text"
                      id="person"
                      name="person"
                      className="form-control"
                      style={this.formStyle}
                      placeholder={t('person') + this.returnMsgCar(this.state.car_type, '', 'p')}
                      onChange={(e) => this.handleInputChange(e.target.value, 'person')}
                      value={this.state.person}
                      required
                    />
                  )}
                </Translation>
              </div>
            </div>
            <div className="row">
              <div className="form-group col">
                <Translation>
                  {(t, { i18n }) => (
                    <input
                      type="text"
                      id="luggage"
                      name="luggage"
                      className="form-control"
                      style={this.formStyle}
                      placeholder={t('luggage') + this.returnMsgCar(this.state.car_type, '', 'l')}
                      onChange={(e) => this.handleInputChange(e.target.value, 'luggage')}
                      value={this.state.luggage}
                      required
                    />
                  )}
                </Translation>
              </div>
            </div>
          </div>
          <div id="step-2" className="step pt-4">
            <h5 className="mb-3">
              <Translation>{(t, { i18n }) => t('Pickupinformation')}</Translation>
            </h5>
            <div className="row pt-2">
              <div className="form-group col">
                <select
                  name="pick_up_type"
                  id="pick_up_type"
                  className="form-control"
                  style={this.formStyle}
                  value={null}
                  onChange={({ target: { value } }) => this.setState({ pickupType: value })}
                  required
                >
                  <Translation>{(t, { i18n }) => <option value="">{t('option.default')}</option>}</Translation>
                  <Translation>{(t, { i18n }) => <option value="1">{t('option.location')}</option>}</Translation>
                  <Translation>{(t, { i18n }) => <option value="2">{t('option.airport')}</option>}</Translation>
                </select>
              </div>
            </div>
            <div
              id="pick_up-location"
              className="pick_up_group"
              style={{ display: this.state.pickupType == 1 ? 'initial' : 'none' }}
            >
              <div className="form-group">
                <Translation>
                  {(t, { i18n }) => (
                    <textarea
                      name="pick_up_address"
                      id="pick_up_address"
                      className="form-control"
                      style={this.formStyle}
                      placeholder={t('address')}
                      onChange={(e) => this.handleInputChange(e.target.value, 'pick_upAddress')}
                      value={this.state.pick_upAddress}
                    ></textarea>
                  )}
                </Translation>
              </div>
              <div className="row">
                <div className="form-group col">
                  <Translation>
                    {(t, { i18n }) => (
                      <input
                        type="text"
                        id="pick_up_postcode"
                        name="pick_up_postcode"
                        className="form-control"
                        style={this.formStyle}
                        placeholder={t('point')}
                        onChange={(e) => this.handleInputChange(e.target.value, 'pick_up_interest')}
                        value={this.state.pick_up_interest}
                      />
                    )}
                  </Translation>
                </div>
              </div>
            </div>
            <div
              id="pick_up-airport"
              className="pick_up_group"
              style={{ display: this.state.pickupType == 2 ? 'initial' : 'none' }}
            >
              <div className="row">
                <div className="form-group col dropdown">
                  <select
                    name="pick_up_airport"
                    id="pick_up_airport"
                    className="form-control"
                    style={this.formStyle}
                    value={this.state.pick_up_airport}
                    onChange={({ target: { value } }) => {
                      this.setState({ pick_up_airport: value })
                    }}
                  >
                    <Translation>{(t, { i18n }) => <option value="1">{t('optionsAirport.su')}</option>}</Translation>
                    <Translation>{(t, { i18n }) => <option value="2">{t('optionsAirport.don')}</option>}</Translation>
                  </select>
                </div>
                <div className="form-group col">
                  <Translation>
                    {(t, { i18n }) => (
                      <input
                        type="text"
                        id="pick_up_flight_number"
                        name="pick_up_flight_number"
                        className="form-control"
                        style={this.formStyle}
                        placeholder={t('FlightNo')}
                        onChange={(e) => this.handleInputChange(e.target.value, 'pick_upFlight')}
                        value={this.state.pick_upFlight}
                      />
                    )}
                  </Translation>
                </div>
              </div>
            </div>
          </div>
          <div id="step-3" className="step pt-4">
            <h5 className="mb-3">
              <Translation>{(t, { i18n }) => t('Dropoffinformation')}</Translation>
            </h5>
            <div className="row pt-2">
              <div className="form-group col">
                <select
                  name="drop_off_type"
                  id="drop_off_type"
                  className="form-control"
                  style={this.formStyle}
                  value={null}
                  onChange={({ target: { value } }) => this.setState({ dropoffType: value })}
                  required
                >
                  <Translation>{(t, { i18n }) => <option value="">{t('option.default')}</option>}</Translation>
                  <Translation>{(t, { i18n }) => <option value="1">{t('option.location')}</option>}</Translation>
                  <Translation>{(t, { i18n }) => <option value="2">{t('option.airport')}</option>}</Translation>
                  ))
                </select>
              </div>
            </div>
            <div
              id="drop_off-location"
              className="drop_off_group"
              style={{ display: this.state.dropoffType == 1 ? 'initial' : 'none' }}
            >
              <div className="form-group">
                <Translation>
                  {(t, { i18n }) => (
                    <textarea
                      name="drop_off_address"
                      id="drop_off_address"
                      className="form-control"
                      style={this.formStyle}
                      placeholder={t('address')}
                      onChange={(e) => this.handleInputChange(e.target.value, 'drop_offAddress')}
                      value={this.state.drop_offAddress}
                    ></textarea>
                  )}
                </Translation>
              </div>
              <div className="row">
                <div className="form-group col">
                  <Translation>
                    {(t, { i18n }) => (
                      <input
                        type="text"
                        id="drop_off_postcode"
                        name="drop_off_postcode"
                        className="form-control"
                        style={this.formStyle}
                        placeholder={t('point')}
                        onChange={(e) => this.handleInputChange(e.target.value, 'drop_off_interest')}
                        value={this.state.drop_off_interest}
                      />
                    )}
                  </Translation>
                </div>
              </div>
            </div>
            <div
              id="drop_off-airport"
              className="drop_off_group"
              style={{ display: this.state.dropoffType == 2 ? 'initial' : 'none' }}
            >
              <div className="row">
                <div className="form-group col dropdown">
                  <select
                    name="drop_off_airport"
                    id="drop_off_airport"
                    className="form-control"
                    style={this.formStyle}
                    value={this.state.drop_off_airport}
                    onChange={({ target: { value } }) => {
                      this.setState({ drop_off_airport: value })
                    }}
                  >
                    <Translation>{(t, { i18n }) => <option value="1">{t('optionsAirport.su')}</option>}</Translation>
                    <Translation>{(t, { i18n }) => <option value="2">{t('optionsAirport.don')}</option>}</Translation>
                  </select>
                </div>
                <div className="form-group col">
                  <Translation>
                    {(t, { i18n }) => (
                      <input
                        type="text"
                        id="drop_off_flight_number"
                        name="drop_off_flight_number"
                        className="form-control"
                        style={this.formStyle}
                        placeholder={t('FlightNo')}
                        onChange={(e) => this.handleInputChange(e.target.value, 'drop_offFlight')}
                        value={this.state.drop_offFlight}
                      />
                    )}
                  </Translation>
                </div>
              </div>
            </div>
          </div>
          <div id="step-4" className="step  pt-3">
            <h5 className="mb-3">
              <Translation>{(t, { i18n }) => t('SpecialNote')}</Translation>
            </h5>
            <div className="form-group">
              <Translation>
                {(t, { i18n }) => (
                  <textarea
                    name="special_Note"
                    id="special_Note"
                    className="form-control"
                    style={this.formStyle}
                    placeholder={t('SpecialNote')}
                    onChange={(e) => this.handleInputChange(e.target.value, 'note')}
                    value={this.state.note}
                  ></textarea>
                )}
              </Translation>
            </div>
            <br />

            <div className="text-center  pt-1 pb-3">
              <button type="button" className="btn-grey-border">
                <Translation>{(t, { i18n }) => t('Back')}</Translation>
              </button>

              <button type="submit" id="btn-submit" className="btn-red-gradient" disabled={this.state.disabled}>
                <Translation>{(t, { i18n }) => t('Booking')}</Translation>
              </button>
            </div>
          </div>
        </form>
      </div>
    )
  }
}
const mapStateToProps = ({ coupon, popup }) => {
  return { coupon, popup }
}

export default connect(mapStateToProps, {
  popupLoading,
  popupError,
  submitBookingMonday,
  submitToCore,
  GetCodeValid,
})(MainForm)
