import React, { Component } from 'react'
import { useTranslation, withTranslation, Translation } from 'react-i18next'
import '../../assets/css/app.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes, faBars } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom'
class MobileMenu extends Component {
  constructor(props) {
    super(props)
    this.state = {
      menuOpen: false,
    }
  }

  handleMenuClick() {
    this.setState({ menuOpen: !this.state.menuOpen })
  }
  render() {
    return (
      <div>
        <div id="mobile-menu" class="d-block d-md-none">
          <div id="mobile-menu-open" onClick={() => this.handleMenuClick()} class="d-inline-block p-4">
            <FontAwesomeIcon icon={faBars} />
          </div>
          <div id="mobile-menu-content" style={{ display: this.state.menuOpen ? 'initial' : 'none' }} class="pt-5">
            <div
              id="mobile-menu-close"
              open={this.state.menuOpen}
              onClick={() => this.handleMenuClick()}
              class="text-right pb-4 pr-4"
            >
              CLOSE <FontAwesomeIcon icon={faTimes} />
            </div>
            <ul class="nav-main text-center">
              <li>
                <Link to="/">
                  <a className="p-3">Home</a>
                </Link>
              </li>

              <li>
                <a className="p-3  " href="#section">
                  <span>Limousine</span> Service
                </a>
              </li>

              <li class="p-2">
                <span>
                  <i class="icon-phone"></i> Phone: 02-016-9950
                </span>
              </li>
              <li class="p-2">
                <span>
                  <i class="icon-envelope"></i>
                  booking@silver-voyage.com
                </span>
              </li>
              <li class="p-2">
                <span>
                  <i class="icon-clock"></i> Everyday: 24 Hours
                </span>
              </li>
              <li class="p-2">
                <span className="pull-right">
                  {' '}
                  <Translation>{(t, { i18n }) => <i onClick={() => i18n.changeLanguage('th')}>TH</i>}</Translation>/
                  <Translation>{(t, { i18n }) => <i onClick={() => i18n.changeLanguage('en')}>EN</i>}</Translation>
                </span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    )
  }
}

export default MobileMenu
